// src/components/fallbackPages/update/UpdateBoundary.js
import { useCallback, useState } from "react";
import { useGlobalStoreContext } from "../context/GlobalStoreContext";
import { batchedSantaApi } from "../api/santaApi";
import { useCompRendering } from "../context/CompRenderingContext";
import communicateBetweenReactAndNative from "../utils/communicateBtwReactAndNative/communicateBetweenReactAndNative";

// this section contains basic window functions that are required to be attached to the window object for easy access
const WindowBoundary = ({ children, fallback }) => {
  const { storeStates, setStoreStates } = useGlobalStoreContext();
  const { states: compRenderStates, setStates: setCompRenderStates } =
    useCompRendering();

  const [isLoading, setIsLoading] = useState(false);

  // ------------------ attaching required functions to window object ------------------

  window.toast = useCallback(
    (msg, type) => {
      setCompRenderStates((p) => ({
        ...p,
        toast1: {
          isRender: true,
          text: msg,
          options: {
            customZIndex: 15,
            type: type || "",
          },
        },
      }));
    },
    [setCompRenderStates]
  );

  window.floatingModal = useCallback(
    (isRender, component, UIOptions, options) => {
      setCompRenderStates((p) => ({
        ...p,
        FloatingCustomModal: {
          isRender,
          component,
          UIOptions,
          options,
        },
      }));
    },
    [setCompRenderStates]
  );

  window.loadingScreen = useCallback(
    (isLoading, msg) => {
      setCompRenderStates((p) => ({
        ...p,
        LoadingScreen1: {
          isRender: isLoading,
          msg: msg || "",
        },
      }));
    },
    [setCompRenderStates]
  );

  window.ask = useCallback(
    (heading, subHeading, labels, saveBtnTxt, onSubmitCallback) => {
      setCompRenderStates((p) => ({
        ...p,
        askForInputs: {
          isRender: true,
          labels,
          heading,
          subHeading,
          onSubmitCallback,
          saveBtnTxt,
        },
      }));
    },
    [setCompRenderStates]
  );

  window.askRadio = useCallback(
    (
      heading,
      subHeading,
      labels,
      saveBtnTxt,
      onSubmitCallback,
      isOtherOptionAvailable
    ) => {
      setCompRenderStates((p) => ({
        ...p,
        askForRadio: {
          isRender: true,
          labels,
          heading,
          subHeading,
          onSubmitCallback,
          saveBtnTxt,
          isOtherOptionAvailable,
        },
      }));
    },
    [setCompRenderStates]
  );

  window.warningModal = useCallback(
    ({ msg, rb_text, lb_text, rb_fn, lb_fn }) => {
      setCompRenderStates((p) => ({
        ...p,
        warningModel: {
          msg,
          leftBtnText: lb_text,
          rightBtnText: rb_text,
          funOnLeftBtn: lb_fn,
          funOnRightBtn: rb_fn,
          isRender: true,
        },
      }));
    },
    [setCompRenderStates]
  );

  window.pickImage = useCallback(
    ({
      pickFrom = "choice",
      cameraType = "front",
      heading = "Pick image",
      allowsEditing = false,
      isRender = true,
      onImagePickEnd = (file, base64URL, readedFile) => {},
    }) => {
      setCompRenderStates((p) => ({
        ...p,
        AskImagePickFrom: {
          isRender: isRender,
          onImagePickEnd: onImagePickEnd,
          pickFrom,
          allowsEditing,
          heading,
          cameraType,
        },
      }));
    },
    [setCompRenderStates]
  );

  window.communicateToNative = useCallback(
    (operation, content = "") => {
      communicateBetweenReactAndNative(
        {
          operation,
          msgFrom: "react",
          content,
        },
        storeStates,
        setStoreStates,
        compRenderStates
      );
    },
    [storeStates, setStoreStates, compRenderStates]
  );

  window.communicateBetweenReactAndNative = useCallback(
    (p) => {
      communicateBetweenReactAndNative(
        p,
        storeStates,
        setStoreStates,
        compRenderStates
      );
    },
    [storeStates, setStoreStates, compRenderStates]
  );

  window.consoleToNative = useCallback((content) => {
    window.communicateToNative("console", content);
  }, []);

  window.handleVibrate = useCallback((impactLevel) => {
    window.communicateToNative("vibration", impactLevel);
  }, []);

  window.openUrl = useCallback((url) => {
    window.communicateToNative("openUrl", url);
  }, []);

  window.copyContent = useCallback((content, msg) => {
    window.communicateToNative("copyContent", content);
    window.toast(msg);
  }, []);

  window.showMsg = useCallback(
    (message, type, callBackFunctionOnCross, options) => {
      // in order to show images, message: img url , type: 'img'
      setCompRenderStates((p) => ({
        ...p,
        warningModel: {
          msg: message,
          rightBtnText: "Ok",
          isRender: true,
          type: type || "",
          callBackFunctionOnCross: callBackFunctionOnCross || null,
          options: options || {},
        },
      }));
    },
    [setCompRenderStates]
  );

  window.throwError = useCallback((error, body = null) => {
    try {
      const serializedError = {
        name: error?.name,
        message: error?.message,
        stack: error?.stack,
        data: error?.data,
        body,
      };

      batchedSantaApi([
        {
          operation: "addNewErrorLog",
          data: {
            serializedError: serializedError,
            body: {
              santaId: localStorage.getItem("SANTA_ID") || null,
            },
          },
        },
      ]);

      window.consoleToNative(serializedError);
      console.error(error);
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (isLoading) {
    return (
      <div className="fix-screen bg-white flex-center">
        <div className=" loader w-[2rem] "></div>
      </div>
    );
  }

  return children;
};

export default WindowBoundary;
