import moment from "moment";
import orderUtils from "./orderUtils";
import { getDistance } from "geolib";

export default function locationUtils() {
  const ORDER_WORK_RADIUS = 200; // in meters
  const LOCATION_RETRACE_INTERVAL = 10;
  const RETRACE_INTERVAL_FOR_ONGOING_ORDER = 10 * 60;
  const RETRACE_INTERVAL_FOR_TODAYS_ORDERS = 20 * 60;
  const RETRACE_INTERVAL_FOR_ON_THE_WAY_ORDER = 5 * 60;

  const updateLatestLocationToDataBase = ({
    lastDBUpdate,
    latestLocation,
    latestLocationTime,
    onUpdateCallBack,
  }) => {
    window.consoleToNative?.({
      message: `lastDBUpdate: ${lastDBUpdate}, latestLocation: ${latestLocation}, latestLocationTime: ${latestLocationTime}`,
      condition: moment(latestLocationTime).isAfter(moment(lastDBUpdate)),
    });
    if (moment(latestLocationTime).isAfter(moment(lastDBUpdate))) {
      window.updateSanta(
        {
          "lastLogin.location": latestLocation,
          "lastLogin.date": latestLocationTime,
        },
        () => {
          onUpdateCallBack(latestLocation, latestLocationTime);
        }
      );
    }
  };

  const santaTracker = ({
    santa,
    fetchedLocationInfo,
    onUpdateCall = (newLocation) => {},
    setInitialState,
  }) => {
    try {
      const {
        backgroundPermission,
        foregroundPermission,
        gpsOn,
        location,
        locationFetchedTime,
      } = fetchedLocationInfo;

      if (!santa) {
        console.log("Santa not available");
        return;
      }

      if (!gpsOn || !foregroundPermission) {
        console.log("Location permission not granted");
        return;
      }

      if (!location.lat || !location.lng) {
        console.log("Location not available");
        return;
      }

      const santaOrdersData = orderUtils().getOptedIndOngoingOrder(santa);

      const lastLogin = santa.lastLogin;
      // case 1: on the way
      if (santaOrdersData?.isOnTheWay) {
        handleSantaArrivesTheLocation({
          order: santaOrdersData.order,
          location,
          setInitialState,
          santa,
        });
        updateLocationForInterval({
          type: "onTheWayOrder",
          location,
          onUpdateCall,
          interval: RETRACE_INTERVAL_FOR_ON_THE_WAY_ORDER,
          santaLastLogin: lastLogin,
        });
      } else if (santaOrdersData?.isOngoing) {
        // case 2: service started, ongoing work
        // check for the santa radial distance from the order location
        const radialDistance =
          santaOrdersData.radialDistanceOfOrderFromSantaLastLocation; // in meters

        if (radialDistance > ORDER_WORK_RADIUS) {
          updateLocationForInterval({
            type: "ongoingOrder",
            location,
            onUpdateCall,
            interval: RETRACE_INTERVAL_FOR_ONGOING_ORDER,
            santaLastLogin: lastLogin,
          });
        }
      } else if (santaOrdersData?.todaysOrders?.length > 0) {
        // case 3: todays orders available

        updateLocationForInterval({
          type: "todaysOrders",
          location,
          onUpdateCall,
          interval: RETRACE_INTERVAL_FOR_TODAYS_ORDERS,
          santaLastLogin: lastLogin,
          setInitialState,
        });
      }
    } catch (error) {
      window.throwError(error);
    }
  };

  const handleSantaArrivesTheLocation = ({
    order,
    location,
    setInitialState,
    santa,
  }) => {
    try {
      const orderLocation = order.addressId?.locationv2;
      if (!orderLocation) {
        return false;
      }

      const distance = getDistance?.(
        { latitude: location.lat, longitude: location.lng },
        { latitude: orderLocation.lat, longitude: orderLocation.lng }
      );

      if (
        distance <= ORDER_WORK_RADIUS &&
        !order.tags?.includes("areaReachedBySanta")
      ) {
        // update the states for order's tag with "areaReachedBySanta"

        setInitialState((p) => ({
          ...p,
          santa: {
            ...p.santa,
            acceptedOrders: p.santa?.acceptedOrders?.map((curr) => {
              if (curr.order?._id === order?._id) {
                return {
                  ...curr,
                  order: {
                    ...curr.order,
                    tags: [...curr.order.tags, "areaReachedBySanta"],
                  },
                };
              } else {
                return curr;
              }
            }),
          },
        }));

        window.updateOrder(order?._id, {
          $push: {
            tags: "areaReachedBySanta",
          },
        });
      }

      return false;
    } catch (error) {
      window.throwError(error);
    }
  };

  /**
   * Updates the location of Santa if the specified time interval has passed since the last login.
   *
   * @param {Object} params - The parameters for the function.
   * @param {Object} params.location - The new location to update.
   * @param {number} params.interval - The time interval in seconds to check before updating the location.
   * @param {Object} params.santaLastLogin - The last login information of Santa.
   * @param {Function} [params.onUpdateCall] - Optional callback function to be called after the location is updated.
   *
   * @throws Will throw an error if the update process fails.
   */
  const updateLocationForInterval = ({
    type,
    location,
    interval, // in seconds
    santaLastLogin,
    onUpdateCall = (newLocation, date) => {},
  }) => {
    try {
      const santaLastLoginTime = santaLastLogin?.date
        ? moment(santaLastLogin.date)
        : null;

      const currentTime = moment();
      if (!santaLastLoginTime) {
        window.updateSanta(
          {
            "lastLogin.location": location,
            "lastLogin.date": currentTime.toISOString(),
          },
          () => {
            onUpdateCall(location, currentTime);
          }
        );
        return;
      }

      const timeDifference = currentTime.diff(santaLastLoginTime, "seconds");
      const isTimeIntervalValid = timeDifference >= interval;

      if (!isTimeIntervalValid) {
        return;
      }

      window.updateSanta(
        {
          "lastLogin.location": location,
          "lastLogin.date": currentTime.toISOString(),
        },
        () => {
          onUpdateCall(location, currentTime);
        }
      );
    } catch (error) {
      window.throwError(error);
    }
  };

  return {
    santaTracker,
    LOCATION_RETRACE_INTERVAL,
    handleSantaArrivesTheLocation,
    updateLatestLocationToDataBase,
  };
}
