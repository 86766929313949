import React, {
  createContext,
  useState,
  memo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import {
  batchedSantaApi,
  checkOTPAndVerifyApi,
  generate_otp,
  get_santa_details_api,
  preDefinedOptions,
  santa_api_with_options,
  update_santa_api,
} from "../api/santaApi";
import { useNavigate } from "react-router-dom";
import { useGlobalStoreContext } from "./GlobalStoreContext";
import santaAppUtils from "../utils/santaAppUtils";
import locationUtils from "../utils/locationUtils";
import { updateOrderForSantaApi } from "../api/orderApi";

const AuthContext = createContext();
let santaLocationTrackerTimeOutId;
// creating the context hook
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = memo(({ children }) => {
  const navigate = useNavigate();
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const SANTA_ID = localStorage.getItem("SANTA_ID");
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const {
    storeStates: { santaAppCredentials, fetchedLocationInfo },
  } = useGlobalStoreContext();
  // ---------------- ** STATES ** ------------------------------

  // otp state
  const [serverRes, setServerRes] = useState({
    isLoading: false,
    data: null,
  });

  //initial santa state
  const [initialState, setInitialState] = useState({
    santa: null,
    santaId: SANTA_ID || null,
    token: ACCESS_TOKEN || null,
    isError: false,
    isSuccess: false,
    isLoading: true,
    message: "",
    type: "",
  });

  // console.log("santa", initialState.santa);
  //phone input state
  const [number, setNumber] = useState("");

  //state errors
  const [error, setError] = useState("");

  //form rendering on condition
  const [authPage, setAuthPage] = useState({
    loginPage: true,
    otpPage: false,
    isLoggedIn: false,
  });

  useEffect(() => {
    // retrace function
    const retrace = () => {
      window.communicateToNative("fetchLocation");

      if (santaLocationTrackerTimeOutId) {
        clearInterval(santaLocationTrackerTimeOutId);
      }

      santaLocationTrackerTimeOutId = setInterval(() => {
        retrace();
      }, locationUtils().LOCATION_RETRACE_INTERVAL * 1000);
    };

    // for santa location tracking
    retrace();

    return () => {
      santaLocationTrackerTimeOutId &&
        clearInterval(santaLocationTrackerTimeOutId);
    };

    // eslint-disable-next-line
  }, [initialState.santa]);

  useEffect(() => {
    // for santa location tracking
    if (!initialState.santa) return;
    santaAppUtils()
      .locationUtils()
      .santaTracker({
        setInitialState,
        santa: initialState.santa,
        fetchedLocationInfo,
        onUpdateCall: (newLocation, date) => {
          setInitialState((p) => ({
            ...p,
            santa: {
              ...p.santa,
              lastLogin: { ...p.santa.lastLogin, location: newLocation, date },
            },
          }));
        },
      });

    // eslint-disable-next-line
  }, [
    fetchedLocationInfo?.location?.lat,
    fetchedLocationInfo?.location?.lng,
    fetchedLocationInfo?.locationFetchedTime,
    initialState.santa,
  ]);

  // need to update the fcm token to the data base if fcm token is not present or mismatch with the santa's current data base info.
  useEffect(() => {
    if (initialState?.santa && santaAppCredentials?.FCMToken) {
      //  match the fcm token with the current fcm token in the data base
      if (
        !initialState.santa?.notification?.FCMToken ||
        initialState.santa?.notification?.FCMToken !==
          santaAppCredentials?.FCMToken
      ) {
        window.updateSanta(
          {
            notification: {
              FCMToken: santaAppCredentials.FCMToken,
            },
          },
          async (res, error) => {
            if (res.isSuccess) {
              setInitialState((prevState) => ({
                ...prevState,
                santa: {
                  ...prevState.santa,
                  notification: {
                    ...prevState.santa.notification,
                    FCMToken: santaAppCredentials.FCMToken,
                  },
                },
              }));
            }
          }
        );
      }
    }

    // eslint-disable-next-line
  }, [santaAppCredentials?.FCMToken, initialState.santa]);

  // window function that should be reconfigured after santa change

  window.updateOrder = useCallback(
    async (orderId, updateObject, callback = () => {}, options) => {
      try {
        const res = await updateOrderForSantaApi({
          queryObject: { _id: orderId },
          updateObject: updateObject,
          tags: options?.tags || [],
        });

        if (res.isSuccess) {
          callback({ isSuccess: true, message: "Order updated successfully" });
        }
      } catch (error) {
        window.throwError(error, {
          fun: "window.updateOrder",
          orderId,
          updateObject,
          options,
        });
        callback({ isSuccess: false, message: error.message });
      }
    },
    []
  );

  window.updateSanta = useCallback(
    async (updateObject, callBack) => {
      try {
        if (!initialState.santa?._id) return;

        const latestLocation = fetchedLocationInfo?.location;
        const latestLocationTime = fetchedLocationInfo?.locationFetchedTime;

        if (latestLocation && latestLocationTime) {
          updateObject = {
            ...updateObject,
            "lastLogin.location": latestLocation,
            "lastLogin.date": latestLocationTime,
          };
        }

        const res = await update_santa_api({
          options: {
            queryObject: { _id: initialState.santa?._id },
            updateObject,
            projectionString:
              "personalDetails address workArea workDetails documentsDetails profileDraftDetails",
            tags: ["crud", "update"],
          },
        });

        if (res.isSuccess) {
          await callBack(res, null);
        }
      } catch (error) {
        window.throwError(error, { fun: "window.updateSanta" });
        callBack({ isSuccess: false }, error);
      }
    },
    [
      initialState.santa?._id,
      fetchedLocationInfo?.location,
      fetchedLocationInfo?.locationFetchedTime,
    ]
  );

  window.updateLatestLocationToDataBase = useCallback(() => {
    locationUtils().updateLatestLocationToDataBase({
      lastDBUpdate: initialState.santa?.lastLogin?.date,
      latestLocation: fetchedLocationInfo?.location,
      latestLocationTime: fetchedLocationInfo?.locationFetchedTime,
      onUpdateCallBack: (newLocation, date) => {
        setInitialState((p) => ({
          ...p,
          santa: {
            ...p.santa,
            lastLogin: { ...p.santa.lastLogin, location: newLocation, date },
          },
        }));
      },
    });
  }, [
    initialState.santa,
    fetchedLocationInfo?.locationFetchedTime,
    fetchedLocationInfo?.location,
  ]);

  const fetchSantaDetails = useCallback(
    async (callback) => {
      try {
        if (!initialState.santaId) return;

        const res = await batchedSantaApi([
          // get santa details
          preDefinedOptions?.santaDetailsGet(),
        ]);

        // extracting the santa details from the response
        const santaDetailsRes = res[0]?.send;

        if (santaDetailsRes?.isSuccess) {
          callback({
            isSuccess: true,
            message: "Santa details fetched",
            santa: santaDetailsRes?.santa,
          });
          setInitialState((p) => ({
            ...p,
            santa: santaDetailsRes?.santa,
            isLoading: false, // will close the skeleton
          }));
        } else {
          callback({ isSuccess: false, message: "Something went wrong" });
          window.toast("Something went wrong", "error");
        }
      } catch (error) {
        callback({ isSuccess: false, message: error.message });
        window.throwError(error, { fun: "fetchSantaDetails" });
      }
    },
    [initialState.santaId]
  );

  window.fetchSantaDetails = fetchSantaDetails;

  /**
   * initialize the opt generation
   */
  const otpGeneration = useCallback(async () => {
    // e && e.preventDefault();
    try {
      setServerRes({
        ...serverRes,
        isLoading: true,
      });
      const res = await generate_otp({
        url: REACT_APP_BACKEND_URL,
        options: {
          operationType: "dataRead", // "dataRead" , "dataUpdate" , "dataWrite" , "dataDelete"
          skip: 0, // for dataRead only,
          limit: 0, // for dataRead only,
          projection: "", // for dataRead only,
          tags: ["login", "otpGenerate"], // for more information pass some specified strings
          token: "",
          data: { number },
        },
      });
      if (res.isSuccess) {
        setServerRes({
          isLoading: false,
          data: res,
        });
        // will open the otp page
        setAuthPage({
          ...authPage,
          otpPage: true,
          loginPage: false,
        });
        window.toast("OTP Sent");
      } else {
        setServerRes({
          ...serverRes,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [REACT_APP_BACKEND_URL, number, serverRes, authPage]);

  const checkOTP = async (inputedOTP) => {
    const res = await checkOTPAndVerifyApi({
      otp: inputedOTP,
      hashOtp: serverRes.data.hash,
      phoneNumber: number,
      options: {
        operationType: "dataRead", // "dataRead" , "dataUpdate" , "dataWrite" , "dataDelete"
        skip: 0, // for dataRead only,
        limit: 0, // for dataRead only,
        projection: "", // for dataRead only,
        tags: ["login", "otpVerify"], // for more information pass some specified strings
        token: "",
        data: {
          otp: inputedOTP,
          hashOtp: serverRes.data.hash,
          phoneNumber: number,
        },
      },
    });
    if (res.isSuccess) {
      localStorage.setItem("ACCESS_TOKEN", res.santa.token);
      localStorage.setItem("SANTA_ID", res.santa._id);
      setInitialState((prevState) => ({
        ...prevState,
        token: res.santa.token,
        santaId: res.santa._id,
      }));
      setAuthPage((prevAuthPage) => ({
        ...prevAuthPage,
        loginPage: false,
        otpPage: false,
        isLoggedIn: true,
      }));
      if (
        res.santa?.profileDraftDetails?.onboardingStatus === "bypassed" ||
        res.santa?.profileDraftDetails?.onboardingStatus === "approved"
      ) {
        window.toast("Logged In");
        navigate("/");
      } else {
        window.toast("OTP Verified");
        navigate("/onboarding");
      }
      return { matched: true };
    } else {
      return { matched: false };
    }
  };

  const getSanta = async ({ extraQuery, extraTag, pipeline }) => {
    try {
      const tags = ["crud", "get_santa_details"];
      if (extraTag) {
        tags.push(extraTag);
      }

      let queryObject = {
        _id: initialState.santaId,
      };

      if (extraQuery) {
        queryObject = { ...queryObject, ...extraQuery };
      }

      const options = {
        queryObject: pipeline ? {} : queryObject,
        projectionString: "",
        pipeline: pipeline,
        tags: tags,
        token: initialState.token,
      };

      const res = await get_santa_details_api({ options: options });
      if (res.isSuccess) {
        return res;
      } else {
        if (res.invalidToken) {
          logout();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //log out a santa
  const logout = () => {
    window && localStorage.clear();
    window.location.reload();
    window.location.href = "/";
  };

  const resendOTPFunc = async () => {
    await otpGeneration();
  };

  const handleSantaUpdate = async ({ updateObject }) => {
    try {
      const options = {
        queryObject: { _id: initialState.santaId },
        updateObject,
        tags: ["crud", "update"],
        token: initialState.token,
      };
      const res = await update_santa_api({ options });
      if (res.isSuccess) {
        setInitialState((p) => ({ ...p, santa: res.santa }));
      }
    } catch (error) {
      return {
        isSuccess: false,
        message: error.message,
      };
    }
  };

  const switchAccount = async ({ accountId }) => {
    try {
      const res = await santa_api_with_options({
        options: {
          operationType: "dataRead", // "dataRead" , "dataUpdate" , "dataWrite" , "dataDelete"
          skip: 0, // for dataRead only,
          limit: 0, // for dataRead only,
          projection: "", // for dataRead only,
          tags: ["login", "switchAccount"], // for more information pass some specified strings
          token: "",
          superAdminId: initialState.santaId,
          accountId: accountId,
        },
      });

      if (res.isSuccess) {
        setInitialState({
          ...initialState,
          token: res.santa.token,
          santaId: res.santa._id,
        });
        localStorage.setItem("ACCESS_TOKEN", res.santa.token);
        localStorage.setItem("SANTA_ID", res.santa._id);
        setAuthPage({
          loginPage: false,
          otpPage: false,
          isLoggedIn: true,
        });

        window.toast("Account switched successfully!");
      } else {
        window.toast("Couldn't switch account!");
      }
    } catch (error) {
      window.toast("Couldn't switch account!");
      console.log(error);
    }
  };

  //input border css
  const inputBorder = "border-b-[1px] border-[rgba(0,0,0,0.3)] py-3 my-3";

  return (
    <AuthContext.Provider
      value={{
        inputBorder,
        authPage,
        setAuthPage,
        number,
        setNumber,
        initialState,
        setInitialState,
        logout,
        error,
        setError,
        resendOTPFunc,
        otpGeneration,
        checkOTP,
        serverRes,
        getSanta,
        handleSantaUpdate,
        switchAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export default AuthContext;
